import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { ContactLink } from '../components/ContactLink';
import SEO from '../components/core/Seo';
import Layout from '../layouts/Layout';
import BlankPage from '../templates/BlankPage';
import InfoPage from '../templates/InfoPage';
import { color, pixels, typography } from '../theme';

const Divider = styled.hr`
  background-color: ${color.grey[50]};
  height: ${pixels.one};
  border: none;
  margin-bottom: 3rem;
`;

const Section = styled.div`
  margin-bottom: 3rem;
  color: ${color.grey[90]};

  :last-child {
    margin-bottom: 0;
  }
  
  h4 {
    font-family: ${typography.family.title};
    font-size: ${typography.size.f4};
    margin-bottom: 0.5em;
    color: ${color.grey[100]};
  }

  ul {
    padding-inline-start: 1rem;
    
    li {
      margin-bottom: 0.5em;
    }
  }

  p {
    margin-bottom: 1em;

    :last-child {
      margin-bottom: 0;
    }
  }

  .listmargin {
    margin-bottom: 0.25em;
  }
`;

const SmallPrint = styled.div`
  margin-bottom: 3rem;
  color: ${color.grey[80]};
  font-size: ${typography.size.f7};

  :last-child {
    margin-bottom: 0;
  }

  h4 {
    font-family: ${typography.family.title};
    font-size: ${typography.size.f6};
    margin-bottom: 0.5em;
    color: ${color.grey[100]};
  }

  ul {
    padding-inline-start: 1rem;

    li {
      margin-bottom: 1em;
    }
  }

  p {
    margin-bottom: 1em;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <InfoPage span="Privacy Policy" title="What we do with your data">
        <BlankPage>
          <Section>
            <h4>What data we collect</h4>
            <p className="listmargin">The personal data we may collect from you includes:</p>
            <ul>
              <li>Full name, e-mail address, phone number, etc.</li>
            </ul>
            <p className="listmargin">Anonymous user data via privacy-focused analytics:</p>
            <ul>
              <li>We collect anonymous insights that tell us how users use our website. For example, which pages are clicked on and what type of device and web browser they used.</li>
            </ul>
          </Section>
          <Section>
            <h4>Why we need it</h4>
            <p>We use privacy-friendly analytics software to collect anonymous information about how users use this website. We do this to help make sure the site is meeting the needs of our customers and to help us make improvements.</p>

            <p className="listmargin">Our analytics software tracks:</p>
            <ul>
              <li>Which pages have been viewed.</li>
              <li>How long users spend on each page.</li>
              <li>What devices and browsers are being used to access the site (Safari via iPhone / Chrome via Android etc).</li>
              <li>Which country the user is in (UK, France, etc).</li>
              <li>How our users got to the site (Google, Bing, Facebook, etc).</li>
            </ul>
          </Section>
          <Section>
            <h4>What we do with your data</h4>
            <p className="listmargin">The data we collect will not be shared with any other organisations.</p>
            <ul>
              <li>We <b>will never</b> sell or rent your data to third parties.</li>
              <li>We <b>will never</b> share your data with third parties for marketing purposes.</li>
              <li>We may share your data if we are required to do so by law - for example, by court order, or to prevent fraud or other crime.</li>
            </ul>
          </Section>
          <Section>
            <h4>How long we keep your data</h4>
            <p className="listmargin">We will only retain your personal data for as long as:</p>
            <ul>
              <li>It is needed for the purposes set out in this document.</li>
              <li>The law requires us to.</li>
            </ul>
          </Section>
          <Section>
            <h4>Children’s privacy protection</h4>
            <p>Our services are not designed for, or intentionally targeted at, children 13 years of age or younger. We do not intentionally collect or maintain data about anyone under the age of 13.</p>
          </Section>
          <Section>
            <h4>How we protect your data and keep it secure</h4>
            <p>We are committed to doing all that we can to keep your data secure. We have set up systems and processes to prevent unauthorised access to your data.</p>
          </Section>
          <Section>
            <h4>Your rights</h4>
            <p className="listmargin">You have the right to request:</p>
            <ul>
              <li>Information about how your personal data is processed.</li>
              <li>A copy of that personal data.</li>
              <li>That anything inaccurate in your personal data is corrected immediately.</li>
            </ul>
            <p className="listmargin">You can also:</p>
            <ul>
              <li>Raise an objection about how your personal data is processed.</li>
              <li>Ask that the processing of your personal data is erased if there is no longer a justification for it.</li>
              <li>Ask that the processing of your personal data is restricted in certain circumstances.</li>
            </ul>
          </Section>
          <Section>
            <h4>Links to other websites</h4>
            <p>This website may contain links to other websites. This privacy policy only applies to knotsmassage.co.uk</p>
          </Section>
          <Section>
            <h4>Changes to this policy</h4>
            <p>We may update our privacy policy in the future. In that case, the ‘last updated’ date at the bottom of this page will also change. Any changes will not affect the data collected before the time of last update.</p>
          </Section>
          <Section>
            <h4>Contact us to make a complaint</h4>
            <p>Please contact us if you have any questions about anything in this document or think that your personal data has been misused or mishandled.</p>
          </Section>
          <Divider />
          <Section>
            <p>Last Updated: 25/07/2021 15:00</p>
          </Section>
        </BlankPage>
        <ContactLink />
      </InfoPage>
    </Layout>
  );
};

export default PrivacyPolicy;
